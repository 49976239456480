import React from 'react'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import abt from '../'
// import abt from 'src/Assets/images/firerock-daimonds-img/abtfirerock.png'
import abt from "../../../../Assets/images/firerock-daimonds-img/abtfirerock.png"
import Button from 'react-bootstrap/Button';
import { Link, NavLink } from 'react-router-dom';
const AboutFirerock = () => {
  return (
    <>
    <div className='fd_about_firerock_diamond'>
     <Container>
        <Row>
         <Col md={6}  sm={12} lg={6}>
           <div className='fd_abt_img'>
            <img src={abt} alt='About Firerock Diamonds' className='img-fluid'/>
           </div>
         </Col>
         <Col md={6}  sm={12} lg={6} className='d-flex align-items-center'>
            <div className='fd_about_firefocks'> 
            {/* <div className='heading-wrapper'>   */}
            <h2 className='fd_headings'>About Firerock Diamonds </h2>
            <p className='fd_paras'>David Joshua is Firerock Diamonds owner and founder who operates in New York City’s Diamond District. 
                He has long-lasting ties with top diamond manufacturers and is able to provide clientele with bespoke 
                service and unmatched access to a vast selection of diamonds and precious gemstones, 
                directly from the source, at the most competitive prices.</p>
                <NavLink to="/our-story"><Button variant="outline-dark" className='firerock_button'>Read More</Button></NavLink>
                {/* </div> */}
                </div>
         </Col>
        </Row>
     </Container>
    </div>
    </>
  )
}

export default AboutFirerock
